/*!
Theme Name: Arbinger NL
Author: AASHQ
Description: WordPress template for Arbinger NL
Version: 0.0.1
Tags: arbinger, institute
*/
/* START Variables 
    blue   color: #00a5e1;
    brown  color: #736357;	
	lightbrown color: #bfb9b7;
*/
/* END Variables */
/* START TOP LEVEL */
html {
	overflow-x: hidden;
	scroll-behavior: smooth;
}

body {
	margin-top: 80px;
	font-family: ProximaNova-Regular, Helvetica, Arial, serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 19pt;
	margin-bottom: 0px;
	color: #736357;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: ProximaNova-Regular, Helvetica, Arial, serif;
	font-weight: 300;
}

h1 {
	color: #00a5e1;
	font-weight: 800;
	font-size: 52pt;
}

h2 {
	color: #00a5e1;
	font-weight: 300;
	font-size: 40pt;
	padding-bottom: 0px;
}

h2.big {
	color: #00a5e1;
	font-size: 32px;
	font-weight: 300;
}

h2.huge {
	color: #00a5e1;
	font-size: 40px;
	font-weight: 300;
}

h2 b {
	font-weight: 800;
	font-size: 40pt;
}

h3 {
	font-family: ProximaNova-Light, Helvetica, Arial, sans-serif;
	color: #00a5e1;
	font-weight: 500;
	font-size: 40pt;
	padding-bottom: 0px;
}

h4 {
	color: #00a5e1;
	font-weight: 800;
	font-size: 12pt;
	padding-bottom: 20px;
	text-transform: uppercase;
}

/* blue small font */
h5 {
	color: #00a5e1;
	font-family: ProximaNova-Extrabld, Helvetica, Arial, sans-serif;
	font-weight: 700;
	font-size: 13pt;
	padding-bottom: 10px;
}

h6 {
	color: #736357;
	font-family: ProximaNova-Extrabld, Helvetica, Arial, sans-serif;
	font-weight: 700;
	font-size: 10pt;
}

img {
	/* even uitgeschakeld door ferdi
  max-width: 100%;*/
}

/* START WORDPRESS THINGIES */
.alignright {
	float: right;
}

.alignleft {
	float: left;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignright {
	float: right;
	margin: 0px 0px 10px 10px;
}

img.alignleft {
	float: left;
	padding: 0px 10px 10px 0px;
}

img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

/* END WORDPRESS THINGIES */
.brown {
	color: #736357;
}

.blue {
	color: #00a5e1;
}

div.grey {
	background-color: #eeedec;
}

/* END TOP LEVEL */
/* START NAVBAR */
#topNavbarWrapper {
	background: white;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 10;
}

.navbar {
	padding: 5px 0;
	border-bottom: 0px solid #f1f1f1;
	box-shadow: 1px 0 7px 0px rgba(0, 0, 0, 0.4);
}

.navbar-logo {
	padding: 0 40px;
	border-right: 1px solid #bfb9b7;
}

.navbar-logo img {
	height: 40px;
}

.navbar-nav {
	margin-left: 15px;
}

.nav-link {
	font-size: 14px;
	text-transform: uppercase;
}

nav-link navbar-nav > li > a {
	padding-top: 5px;
	padding-bottom: 5px;
	line-height: 20px;
	margin-top: 15px;
	margin-bottom: 10px;
}

li.active > a,
li.active > a:link,
li.active > a:visited,
li.active > a:focus,
li.active > a:hover {
	background-color: #00a5e1 !important;
	color: white !important;
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

li > a:hover,
li > a:focus,
.dropdown-item a:hover,
.dropdown-item a:focus {
	color: #736357;
	text-decoration: none;
	background-color: #f5f5f5 !important;
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.dropdown {
	color: #736357;
}

.dropdown-item a {
	color: #736357;
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: #00a5e1;
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

/* END NAVBAR */
/* START VIDEOBANNER */
.videoBannerContainer {
	height: 480px;
}

.videoBannerVideo {
	position: fixed;
	z-index: -10;
	top: 0;
	left: 0;
	width: 100vw;
	height: auto;
}

.videoBannerCaptions {
	padding: 120px 10px 30px !important;
	color: white;
	text-align: center;
}

.image-banner-button {
	img {
		width: 100%;
		object-fit: cover;
		height: 320px;
	}

	.image-banner-caption {
		width: 75%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		text-align: center;

		h2 {
			color: white;
			margin-bottom: 0.5em;
		}
	}
}

.videoBannerCaptions h2 {
	color: #ffffff;
	font-size: 30px;
}

.videoBannerCaptions h2.line-1 {
	font-family: ProximaNova-Extrabld, Helvetica, Arial, sans-serif;
	font-weight: 800;
	font-size: 72px;
	text-transform: uppercase;
}

/* END VIDEOBANNER */
.page-heading {
	max-width: 100%;
	width: 100%;
	height: auto;
	padding: 160px 0 160px 0;
	text-align: center;
	position: relative;
}

.wp-block-separator {
	border-bottom: 0 !important;
}

.page-heading h2 {
	color: white;
	font-size: 48px;
	font-weight: 400;
	/* text-shadow: 1px 1px 5px rgba( 0, 0, 0, 0.6 ); */
}

.page-heading span {
	color: white;
	font-size: 20px;
	font-weight: 400;
	/* text-shadow: 1px 1px 3px rgba( 0, 0, 0, 0.6 ); */
}

.page-heading .overlay {
	background: rgba(115, 99, 87, 0.75);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	padding-top: 160px;
}

.content-row {
	padding-top: 40px;
	padding-bottom: 40px;
}

.text-col {
	color: #736357;
	text-align: center;
}

.text-col img {
	height: 96px;
}

.text-col h2 {
	margin: 20px 0;
	color: #00a5e1;
	font-family: ProximaNova-Light, Helvetica, Arial, sans-serif;
	font-size: 24px;
	font-weight: 300;
}

.text-col p:last-of-type {
	margin-bottom: 32px;
}

.text-col.left {
	text-align: left;
}

.speaker-thumb {
	width: 100%;
	margin-top: 30px;
	border: 2px solid #736357;
	border-radius: 50%;
	transition: border 0.2s ease-in-out;
}

.speaker-thumb:hover {
	border: 5px solid #736357;
	cursor: pointer;
	transition: border 0.2s ease-in-out;
}

.speaker-img-header {
	padding: 100px 0 100px 50px;
	margin: 70px 0 70px 0;
}

.speaker-img-header .block-title {
	padding: 20px;
	background: #00a5e1;
	color: white;
	font-weight: 400;
	text-transform: uppercase;
	display: block;
}

.speaker-img-header span {
	margin-top: 60px;
	color: #736357;
	font-weight: 400;
	display: block;
}

.speaker-bio h4 {
	color: #00a5e1;
	font-size: 20px;
}

.post.hentry {
	margin-top: 100px;
}

.post.hentry:first-of-type {
	margin: 0;
}

.attachment-full {
	max-width: 100%;
	width: 100%;
	height: auto;
}

.entry-title,
.entry-title a {
	color: #008bbd;
	font-size: 48px;
	font-family: ProximaNova-Light, Helvetica, Arial, sans-serif;
}

.entry-title a:hover {
	color: #736357;
	text-decoration: none;
}

.entry-info {
	color: #736357;
	font-size: 22px;
	font-weight: 400;
}

.entry-button {
	padding: 10px;
	border: 1px solid #00a5e1;
	color: #00a5e1;
	text-transform: uppercase;
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.entry-button:hover {
	background: #00a5e1;
	color: white;
	text-decoration: none;
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.contact-container {
	margin-top: 70px;
	margin-bottom: 30px;
	padding: 30px;
	box-shadow: 0 0 20px #d0d0d0;
}

.contact-heading {
	font-family: ProximaNova-Light, Helvetica, Arial, sans-serif;
	color: #00a5e1;
	font-size: 20pt;
}

.contact-input-container {
	margin-top: 30px;
}

.contact-input {
	width: 100%;
	padding: 10px;
	border: none;
	background: #eeedec;
}

.contact-textarea {
	width: 100%;
	height: 120px;
	padding: 10px;
	border: none;
	background: #eeedec;
	resize: none;
}

.contact-info-row {
	padding: 70px 0;
}

.contact-info-col {
	color: #736357;
}

.contact-info-col h2 {
	font-size: 24px;
	font-weight: 400;
}

.agenda-intro-wrapper {
	background: #00a5e1;
	color: white;
}

.agenda-heading-row {
	border-bottom: 1px solid #736357;
}

.agenda-heading-cell {
	padding: 25px 15px;
	color: #00a5e1;
	text-transform: uppercase;
}

.agenda-row:nth-child(even) {
	background: #f7f6f5;
}

.agenda-cell {
	padding: 15px;
	color: #736357;
	font-size: 13px;
}

div.button-spacing {
	margin-top: 10px;
}

.agenda-cell span {
	white-space: nowrap;
}

/*
.agenda-cell:nth-child( 2 ), .agenda-heading-cell:nth-child( 2 )
{
  border-right: 1px solid $brown;
}
.agenda-cell:nth-child( 4 ), .agenda-heading-cell:nth-child( 4 )
{
  border-right: 1px solid $brown;
}
*/
.agenda-button {
	padding: 10px;
	border: 1px solid #00a5e1;
	background: white;
	color: #00a5e1;
	transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.agenda-button:hover {
	background: #00a5e1;
	color: white;
	text-decoration: none;
	transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

footer {
	padding-top: 70px;
	padding-bottom: 70px;
	background: #00a5e1;
	color: white;
}

footer .navbar-nav,
footer .navbar-nav li {
	padding: 0;
	margin: 0;
}

footer .navbar-nav li {
	margin-top: 10px;
}

footer .navbar-nav li:first-of-type {
	margin: 0;
}

footer .navbar-nav li a {
	color: white;
}

footer .navbar-nav li a:hover {
	color: white;
	font-weight: 400;
	text-decoration: none;
}

.copyright {
	font-size: 12px;
}

a.button,
input.button {
	border: 1px solid #736357;
	background: white;
	color: #736357;
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
	font-family: ProximaNova-Medium, Helvetica, Arial, sans-serif;
	font-weight: 500;
	letter-spacing: +0.5px;
	text-transform: uppercase;
	padding: 5px 20px;
	text-align: center;
}

a.button {
	margin: 0px;
}

input.button {
	height: 35px;
}

a.button:hover,
input.button:hover {
	background: #736357;
	color: white;
	text-decoration: none;
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

a.button-blue,
input.button-blue {
	color: #00a5e1 !important;
	border: 1px solid #00a5e1;
}

a.button-blue:hover,
input.button-blue:hover {
	background: #00a5e1;
	color: white !important;
	text-decoration: none;
	cursor: pointer;
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

a.button-blue-inverted {
	padding: 15px 20px 15px 20px;
	margin: 20px;
	color: white !important;
	background: #00a5e1;
	border: 1px solid white;
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

a.button-blue-inverted:hover {
	color: #00a5e1 !important;
	background: white;
	cursor: pointer;
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.button-brown {
	color: #736357 !important;
	border: 1px solid #736357;
}

.button-brown:hover {
	background: #736357;
	color: white !important;
	text-decoration: none;
	cursor: pointer;
	transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.auto-size.fillX {
	width: 100%;
}

hr.spacer {
	margin-top: 50px;
	margin-bottom: 50px;
}

/* HELPER CLASSES */
.pb0 {
	padding-bottom: 0px;
}

.pt0 {
	padding-top: 0px;
}

.pb10 {
	padding-bottom: 10px;
}

.pt10 {
	padding-top: 10px;
}

.pb20 {
	padding-bottom: 20px;
}

.pt20 {
	padding-top: 20px;
}

.pb30 {
	padding-bottom: 30px;
}

.pt30 {
	padding-top: 30px;
}

.pb40 {
	padding-bottom: 40px;
}

.pt40 {
	padding-top: 40px;
}

.pa10 {
	padding: 10px;
}

.ml25 {
	margin-left: 25px;
}

.lh30 {
	line-height: 30px;
}

/* END HELPER CLASSES */
td {
	/*vertical-align: top;*/
}

.rightBorder {
	border-right: 1px solid #736357;
}

.leftBorder {
	border-left: 1px solid #736357;
}

.homeCase {
	position: relative;
	width: 20%;
	background: red;
	transition: box-shadow 0.2s ease-in-out;
	margin-top: -8px;
}

.homeCase:hover {
	box-shadow: 0 0 50px 3px rgba(0, 0, 0, 0.4) inset;
	transition: box-shadow 0.2s ease-in-out;
}

.homeCase:hover img {
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
}

.homeCase:hover:after {
	opacity: 1;
	bottom: 50%;
	transition: opacity 0.2s ease-in-out, bottom 0.2s ease-in-out;
}

.homeCase:before {
	content: "";
	display: block;
	padding-top: 100%;
}

.homeCase:after {
	content: "BEKIJK CASE";
	background: transparent;
	padding: 2px 6px;
	border: 1px solid white;
	color: white;
	font-size: 12px;
	white-space: nowrap;
	position: absolute;
	bottom: 0%;
	left: 50%;
	transform: translate(-50%, 50%);
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.2s ease-in-out, bottom 0.2s ease-in-out;
}

.homeCase a {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.homeCase img {
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: opacity 0.2s ease-in-out;
}

.contact-widget-page p {
	text-shadow: -1px -1px 1px white, 1px 1px 1px white, 1px -1px 1px white,
		-1px 1px 1px white;
}

footer a .fa,
footer a:hover .fa {
	color: white;
	text-decoration: none !important;
}

.case-studies-archive-row {
	padding-top: 50px;
	padding-bottom: 80px;
}

.case-studies-archive-case {
	margin-top: 30px;
}

.case-studies-archive-header-image {
	width: 100%;
	height: 257px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	transition: box-shadow 0.2s ease-in-out;
}

.case-studies-archive-content {
	padding-top: 40px;
}

.case-studies-archive-logo {
	width: 215px;
	height: auto;
	padding: 20px 30px 20px 0;
	margin-right: 30px;
	border-right: 1px solid #736357;
	box-sizing: content-box;
	float: left;
}

.case-studies-archive-about {
	padding: 20px 0 20px 0;
	text-align: justify;
	line-height: 18px;
}

.case-studies-archive-header-image:hover {
	cursor: pointer;
	box-shadow: 0 0 300px 20px rgba(0, 0, 0, 0.75) inset;
	transition: box-shadow 0.2s ease-in-out;
}

.case-studies-archive-header-image:hover:after {
	opacity: 1;
	bottom: 50%;
	transition: opacity 0.2s ease-in-out, bottom 0.2s ease-in-out;
}

.case-studies-archive-header-image:after {
	content: "WATCH CASE STUDY";
	background: transparent !important;
	padding: 2px 6px;
	border: 1px solid white;
	color: white;
	font-size: 12px;
	white-space: nowrap;
	box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.75);
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
	position: absolute;
	bottom: 0%;
	left: 50%;
	transform: translate(-50%, 50%);
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.2s ease-in-out, bottom 0.2s ease-in-out;
}

.blog-side-col ul {
	list-style: none;
	padding: 0;
	padding-top: 3px;
}

.blog-side-col ul a {
	overflow: hidden;
	display: list-item;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: #00a5e1;
	background: transparent !important;
	border-bottom: 1px solid transparent;
	transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
}

.blog-side-col ul a:hover {
	color: #736357;
	background: transparent !important;
	border-bottom-color: #736357;
	transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.blog-side-col {
	.post-date {
		display: none;
	}
}

.assessment-landing-heading h1 {
	color: white;
	font-family: ProximaNova-Black, Helvetica, Arial, sans-serif;
	font-weight: 800;
	text-transform: uppercase;
}

.assessment-landing-heading hr {
	border-top: 3px dotted white;
}

.activity-lightbox {
	position: fixed;
	z-index: 9999999;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(115, 99, 87, 0.5);
	display: none;
	opacity: 0;
}

.activity-lightbox .content {
	max-width: 880px;
	max-height: calc(100% - 40px);
	padding: 30px;
	background: white;
	color: #736357;
	box-shadow: 0 0 10px 0 black;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow-y: scroll;
}

.activity-lightbox h3 {
	font-size: 26px;
}

@media (max-width: 480px) {
	.activity-lightbox {
		position: fixed;
		z-index: 9999999;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(115, 99, 87, 0.5);
		display: none;
		opacity: 0;
	}

	.activity-lightbox .content {
		width: 95%;
		padding: 10px;
		background: white;
		color: #736357;
		box-shadow: 0 0 10px 0 black;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.activity-lightbox h2 {
		font-size: 16px;
	}

	.activity-lightbox h3 {
		font-size: 14px !important;
	}

	.participant input {
		width: 85% !important;
	}

	.border-left {
		border-left: 0px !important;
	}

	body {
		margin-top: 65px !important;
	}
}
.border-left {
	border-left-color: #8d8d8d;
	border-left-style: solid;
	border-left-width: 1px;
}

select.dropdown {
	margin: 10px;
	background-color: #eeedec;
	border: none;
	width: 250px;
	height: 26pt;
	font-size: 12pt;
	padding-left: 18px;
}

.mr-10 {
	margin-right: -10px;
}

.jobtitle {
	font-weight: 600;
	margin-top: -20px;
	padding-bottom: 20px;
}

.groeienbloeievent {
	width: 100%;
	max-height: 400px;
	background: url("/wp-content/uploads/2018/04/groeienenbloeienevent.jpg");
	background-size: cover;
	background-position: center center;
}

.center-group {
	width: 300px;
	margin-left: auto;
	margin-right: auto;
}

.mc4wp-checkbox-contact-form-7 {
	display: block;
}

.mc4wp-checkbox-contact-form-7 label {
	width: 100%;
}

.mc4wp-email {
	background-color: #ffffff;
	color: #736357;
	border: 1px solid #ffffff;
	width: 240px;
	height: 34px;
	font-size: 11pt;
	margin-bottom: 0px;
	padding: 5px;
	padding-left: 10px;
	margin: 0px;
}

.mc4wp-btn {
	background-color: rgba(255, 255, 255, 0);
	color: #ffffff;
	border: 1px solid #ffffff;
	height: 34px;
	width: 100px;
	text-align: center;
	font-size: 11pt;
	margin-bottom: 0px;
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	margin: 0px;
}

.mc4wp-btn:hover {
	background-color: white;
	color: #00a5e1;
}

.w-30 {
	width: 30% !important;
}

/*# sourceMappingURL=style.css.map */

.book-input {
	border-radius: 3px;
	background-color: #eeedec;
	border: none;
	width: 100%;
	height: 26pt;
	font-size: 12pt;
	padding-left: 18px;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
}

.book-input[type="submit"] {
	border-radius: 3px;
	padding-left: 6px;
	background-color: white;
	border: 3px solid #736357;
	cursor: pointer;
	color: #736357;
	transition: all 0.2s ease-in-out;
}

.book-input[type="submit"]:hover {
	background-color: #736357;
	color: white;
}

.overlap-banner {
	background: #00a2df;
	color: white;
	text-align: center;
	padding: 1em;
	padding-bottom: 1.6em;
	width: 600px;
	position: absolute;
	margin-left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	max-width: 100%;
}

.videoBannerContainer {
	height: 530px;
}

.overlap-banner + * {
	padding-top: 50px;
}

.overlap-banner h3 {
	color: white;
	margin-bottom: 0.3em;
}

.overlap-banner p {
	line-height: 1.2em;
}

.overlap-banner .button-blue-inverted {
	background: transparent;
	padding: 0.35em 0.5em;
}

.overlap-banner .button-blue-inverted:hover {
	text-decoration: none;
}

@media (max-width: 480px) {
	.overlap-banner {
		position: initial;
		margin-left: 0;
		transform: none;

		width: 100%;
		margin-top: 110px;
	}

	.overlap-banner + * {
		padding-top: initial;
	}
}

.homepageHeader .line-0 {
	color: #736357;
	text-align: left;
}

.homepageHeader .line-1 {
	color: #00a5e1;
	text-align: left;
	font-size: 72px;
}

.live-webinar-widget {
	padding-top: 100px;
	/*margin-bottom: -50px;*/
}

.live-webinar-title {
	font-size: 42px;
	text-align: center;
}

.live-webinar-subtitle {
	text-align: center;
	font-size: 36px;
	color: #736357;
	margin-bottom: 30px;
}

.webinar-block {
	/*  background-color: #00a2df;*/
	padding: 1em;
	text-align: left;
	border: 2px solid;
	border-color: #00a5e1;
	background-color: white;
}

.webinar-block-title {
	color: #00a5e1;
	text-align: left;
	font-size: 24px;
}

.webinar-block .webinar-block-button {
	/*width: 60%;*/
	display: inline-block;
	margin-left: 0px;
	margin-bottom: 0px;
	margin-top: 0px;
}

.webinar-block-6 {
	padding-left: 5em;
	padding-right: 5em;
	margin-top: 20px;
}

.live-webinar-image {
	width: 100%;
	margin-bottom: 40px;
}

.webinar-2nd-block {
	width: 100%;
	padding-top: 230px;
	background: linear-gradient(to bottom, #3aa9e1 0%, #50c7cc 100%);

	.text-wrapper {
		margin: 0 auto;
		max-width: 1000px;

		h3 {
			font-size: 35pt;
			font-weight: 600;
		}
	}
}

.blue-button {
	background-color: #00a5e1;
	padding: 15px 20px;
	color: white;

	&:hover {
		color: white;
	}

	&.inverted {
		background-color: white;
		color: #00a5e1;

		&:hover {
			color: #00a5e1;
		}
	}
}

.webinar-2nd-block h3,
.webinar-4th-block h3 {
	color: #ffffff;
}

.webinar-2nd-image {
	text-align: center;
	margin: 0 auto -170px auto;
	max-width: 1200px;
}

.webinar-2nd-image img {
	width: 90%;
}

.webinar-block a.button-blue-inverted:hover {
	color: white !important;
	background: #00a5e1 !important;
}

.webinar-3rd-block h2 {
	margin-top: 40px;
	color: #00a5e1;
	font-size: 72px;
	margin-bottom: 30px;
}

.webinar-3rd-block h3 {
	color: #736357;
	font-size: 22pt;
	line-height: 32pt;
	margin-bottom: 50px;
}

.webinar-3rd-block .button {
	display: inline-block;
	min-width: 30%;
}

.webinar-3rd-block {
	margin: 100px 0 !important;
}

.webinar-3rd-image {
	width: 100%;
}

.titleSmallVideo {
	text-align: center;
	font-size: 16px;
}

.subtitleSmallVideo {
	text-align: center;
	color: #726d67;
	font-size: 11pt;
}

.col-video-small-widget {
	margin-top: 40px;
	margin-bottom: 40px;
}

.previewImage {
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
}

.videoThumbnail:hover {
	cursor: pointer;
}

.videoThumbnail:before {
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	content: url(/wp-content/uploads/2020/04/PlayVideo.png);
	opacity: 1;
}

.videoThumbnail:hover:before {
	content: url(/wp-content/uploads/2020/04/PlayVideoFull.png);
}

.overlay-form {
	background-color: rgba(70, 70, 70, 0.75);
	display: none;
}

.overlay-form-account-exists {
	background-color: rgba(70, 70, 70, 0.75);
	display: none;
}

.overlay-video {
	background-color: rgba(70, 70, 70, 0.75);
	display: none;
}

.overlay-form-home {
	background-color: rgba(70, 70, 70, 0.75);
	display: none;
}

#selectedWebinar {
	margin: 10px;
	width: 100%;
	height: 25px;
	font-size: 20px;
	padding-left: 4px;
	color: grey;
}

#modal-root-home input {
	width: 100%;
}

#modal-root input {
	width: 100%;
}

#modal-root-account-exists input {
	width: 100%;
}

.modal-dialog {
	max-width: 700px;
}

#modal-root-home #opt-in {
	width: auto;
	-ms-transform: scale(1.3); /* IE */
	-moz-transform: scale(1.3); /* FF */
	-webkit-transform: scale(1.3); /* Safari and Chrome */
	-o-transform: scale(1.3); /* Opera */
	transform: scale(1.3);
}

.input-text {
	display: inline;
}

.success-message,
.success-message-register {
	border: 1px solid green;
	padding: 10px;
	margin: 10px;
	font-weight: bold;
	display: none;
	clear: both;
}

.error-message,
.error-message-register {
	border: 1px solid red;
	padding: 10px;
	margin: 10px;
	font-weight: bold;
	display: none;
	clear: both;
}

.error-message-email,
.error-message-email-register {
	border: 1px solid red;
	padding: 10px;
	margin: 10px;
	font-weight: bold;
	display: none;
	clear: both;
}

.error-message-alt,
.error-message-alt-register {
	border: 1px solid red;
	padding: 10px;
	margin: 10px;
	font-weight: bold;
	display: none;
	clear: both;
}

.busy-message,
.busy-message-register {
	margin: 10px;
	font-size: 40px;
	display: none;
	clear: both;
}

.modal-video {
	max-width: 1000px;
}

#submit-button {
	cursor: pointer;
}

.webinar-4th-block {
	padding-top: 80px;
	padding-bottom: 160px;
	margin-bottom: -170px;
	background-color: #00a5e1;
}

.webinar-4th-block p {
	color: white;
	font-size: 11.5pt;
	line-height: 30px;
	margin-bottom: 50px;
}

.webinar-4th-block h3 {
	font-size: 32px;
	margin-bottom: 20px;
}

.webinar-date {
	font-size: 20px;
}

.webinar-2-information {
	font-size: 22pt;
	color: white;
}

.content-with-image,
.content-with-bg-image {
	h2 {
		font-size: 40pt;
	}

	img {
		width: 100%;
		object-fit: contain;
	}
}

.content-with-icon {
	.container {
		column-gap: 20px;

		h3 {
			font-size: 32px;
			font-weight: bold;
		}

		p {
			margin-bottom: 0;
		}

		ol {
			padding-left: 1.25em;

			li {
				&::marker {
					font-weight: bold;
				}
			}
		}

		img {
			width: 70px;
			height: 70px;
		}
	}
}

.content-with-bottom-border {
	.container {
		column-gap: 40px;
		padding-bottom: 20px;
		border-bottom: 1px solid #e0e0e0;
	}

	img {
		width: 110px;
		height: 110px;
		border-radius: 50%;
	}

	h5 {
		font-size: 13pt;
		font-weight: bold;
	}
}

.title-with-desc {
	h3 {
		color: #726d67;
	}
}

.content-with-bg-image {
	background-size: cover;
}

.triple-highlight-2 {
	.content {
		border-top: 1px solid black;

		ol {
			list-style: none;
			counter-reset: li;

			li {
				counter-increment: li;

				&:before {
					content: counter(li) ".";
					font-weight: 700;
					color: #00a5e1;
					margin-left: -1em;
					margin-right: 5px;
				}
			}
		}
	}

	.highlight {
		padding: 0;

		img {
			height: 100px;
		}

		ol,
		ul {
			padding-left: 1em;
		}

		&:not(:last-of-type) {
			border-right: 1px solid black;
		}
	}
}

.result-outcome {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px 50px;
	margin-top: 50px;
	padding-left: 0;
	list-style-type: none;

	li {
		font-family: ProximaNova-Light, Helvetica, Arial, sans-serif;
		line-height: 1.1em;
		font-weight: 300;
	}
}

.buttonBlue {
	color: #00a5e1 !important;

	&:hover {
		color: #fff !important;
	}
}

.participant_count {
	width: auto;
}

.mobile-schedule {
	display: none;

	.schedule-item {
		border: 1px solid #e9ecef;
		padding: 10px;
		border-radius: 5px;
		box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 6px 0px;

		.activity {
			font-size: 24px;
			color: #00a5e1;
			margin-bottom: 15px;
		}

		.date {
			margin-bottom: 5px;
		}

		.apply-for-activity {
			display: inline-block;
			margin-top: 10px;
			padding-block: 5px;
		}

		&:not(:first-of-type) {
			margin-top: 20px;
		}
	}
}

@media (max-width: 768px) {
	.result-outcome {
		grid-template-columns: repeat(2, 1fr);
		gap: 25px;
	}

	.content-with-icon {
		.container {
			flex-wrap: wrap;
			justify-content: center;

			.content-wrapper {
				h3 {
					text-align: center;
				}
			}

			> * {
				width: 100%;
			}
		}
	}

	.content-with-bottom-border {
		.container {
			flex-wrap: wrap;
			justify-content: center;
			row-gap: 20px;

			.content-wrapper {
				width: 100%;
			}
		}
	}

	.page-heading h2 b {
		font-size: 24px;
	}

	.homepageHeader .line-0,
	.homepageHeader .line-1 {
		text-align: right;
	}

	.webinar-3rd-block h3 {
		font-size: 18pt;
	}

	.webinar-3rd-block h2 {
		font-size: 42px;
	}

	.webinar-2nd-block {
		padding-bottom: 110px;
		margin-bottom: -100px;
	}

	.triple-highlight-2 {
		.highlight {
			&:not(:last-of-type) {
				border-right: none;
			}
		}
	}

	.desktop-schedule {
		display: none;
	}

	.mobile-schedule {
		display: block;
	}
}

.inverted-color-row {
	background-color: #00a5e1;
}

.inverted-color-row .titleSmallVideo {
	color: white;
	font-weight: 600;
}

.inverted-color-row .subtitleSmallVideo {
	color: white;
}

input {
	width: 100%;
	padding: 20px;
	border: none;
	background: #eeedec;
}

input[type="submit"] {
	width: auto;
	padding: 10px;
	padding-top: 0px;
	border: 1px solid;
	background-color: #00a5e1;
	color: white;
}

.mr40 {
	margin-right: 40px;
}

select {
	width: 100%;
	border: none;
	background: #eeedec;
	height: 45px;
	padding: 0 20px !important;
	margin: 10px;
	font-size: 14px;
}

label {
	font-size: 16px;
	margin-left: 10px;
	margin-top: 15px;
	font-weight: 600;
	color: #757575;
}
